<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="20">
          <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
            <a-form-model-item label="接收人">
              <EmployeeSelector
                  style="width: 150px"
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        master = arr[0];
                      } else {
                        master = {};
                      }
                    }
                  "
                  :value="master.name ? [master] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="master.name">
                      {{ master.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
            </a-form-model-item>
            <a-form-model-item>
              <a-select
                v-model="form.deptId"
                placeholder="所属部门"
                show-search
                :filter-option="$selectFilterOption"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.status" placeholder="发放状态" style="width: 150px">
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker v-model="form.date" :placeholder="['开始日期', '结束日期']" style="width: 260px" />
            </a-form-model-item>
            
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="4">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <a-button
                v-if="$getPermission($route.path + '/add')"
                @click.prevent="download()"
                style="margin-right: 10px;"
              >导出台账</a-button>
              <a-button
                type="primary"
                v-if="$getPermission($route.path + '/add')"
                @click="$router.push($route.path + '/add')"
              >资料发放</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="接收人" data-index="receiveName"></a-table-column>

        <a-table-column title="所属部门" data-index="deptName" align="center"></a-table-column>
       
        <a-table-column title="发放时间" data-index="grantTime" width="160px" align="center"></a-table-column>
        <a-table-column title="归还时间" data-index="returnTime" width="160px" align="center"></a-table-column>
        <a-table-column title="资料名称">
          <template slot-scope="text">
            <span v-for="item in text.dataList">
              {{ item.name }};
            </span>
          </template>
        </a-table-column>
        <a-table-column title="发放状态" width="100px" align="center">
          <template slot-scope="text">
            <DataDictFinder
                dictType="technicalInformationGrant_status"
                :dictValue="text.status"
                iconType="badge"
                badgeType="color"
              />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="
                  $router.push(`${$route.path}/detail?id=${text.id}`)
                "
              >
                详情
              </a>
              <a href="#" v-if="text.status=='technicalInformationGrant_status_sure'" @click.prevent="remove(text)">归还</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import EmployeeSelector from "@/components/employee-selector";
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import study from "@/mixins/study";
import download from '@/api/download'
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
function fetchList(data) {
  return request({
    url: "/office-service/technicalInformationGrant/list",
    method: "post",
    data
  });
}

function exportFile(data) {
  return download({
    url: "/office-service/technicalInformationGrant/exportExc",
    method: "post",
    data
  });
}

function del(id) {
  return request({
    url: "/office-service/technicalInformationGrant/return/" + id,
    method: "post"
  });
}

export default {
  mixins: [watermark, study, organization],
  components: {
    EmployeeSelector,
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("technicalInformationGrant_status");
    },
  },
  data() {
    return {
      master: {},
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    download() {
        let startDate, endDate;
        const date  = this.form.date;
        if (Array.isArray(date) && date.length === 2) {
          startDate = date[0].format("YYYY-MM-DD");
          endDate = date[1].format("YYYY-MM-DD");
        }
        exportFile({
          deptId: this.form.deptId,
          grantTimeStart: startDate,
          grantTimeEnd: endDate,
          receiveId: this.master.userId,
          status: this.form.status
        })
        .then(blob => {
            saveAs(blob, "台账.xlsx");
        });
    },
    getList() {
      this.loading = true;
      let startDate, endDate;
      const date  = this.form.date;
      if (Array.isArray(date) && date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[1].format("YYYY-MM-DD");
      }
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        deptId: this.form.deptId,
        grantTimeStart: startDate,
        grantTimeEnd: endDate,
        receiveId: this.master.userId,
        status: this.form.status
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.master = {};
      this.getList();
    },
    remove(text) {
      const that = this;
      this.$confirm({
        title: "确定要归还吗？",
        onOk() {
          del(text.id)
            .then(() => {
              that.getList();
            })
            .finally(() => {
            });
        }
      });
    }
  }
};
</script>
